import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getPerguntas() {
    try {
      const res = await axios.get('api/pergunta/');
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getPerguntaById(store, id) {
    try {
      const res = await axios.get(`api/pergunta/${id}/`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async postPergunta(store, pergunta) {
    try {
      const res = await axios.post('/api/pergunta/', pergunta);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async putPergunta(store, pergunta) {
    try {
      const { id } = pergunta;
      const res = await axios.put(`/api/pergunta/${id}/`, pergunta);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async deletePergunta(store, id) {
    try {
      const res = await axios.delete(`/api/pergunta/${id}/`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
