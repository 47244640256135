const toggleWithoutAnimation = state => state.app.sidebar.withoutAnimation;
const config = state => state.app.config;
const palette = state => state.app.config.palette;
const isLoading = state => state.app.isLoading;

const isLoggedIn = state => !!state.token;
const authStatus = state => state.status;

const typeQuestionsMask = state => state.tiposDeRespostaDeTexto;

export {
  // System
  toggleWithoutAnimation,
  config,
  palette,
  isLoading,
  // Old
  isLoggedIn,
  authStatus,
  // New
  typeQuestionsMask,
};
