<template>
  <div
    class="vuestic-widget"
    :class="{'no-header': !headerText}"
  >
    <div
      class="vuestic-widget-header"
      v-if="headerText"
    >{{headerText}}
      <span class="ml-auto">
        <span v-if="button" @click.prevent="$emit('adicionarItem')">
          <el-popover
            class="btn btn-micro plus"
            placement="top-start"
            width="90"
            trigger="hover"
            :content="`Incluir ${headerText}`"
          >
            <i
              slot="reference"
              class="glyphicon glyphicon-plus-sign"
            ></i>
          </el-popover>
        </span>
        <i
          v-if="hide"
          class="ml-4"
          @click="show = !show"
          :class=" show? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
        ></i>
      </span>

    </div>
    <div
      class="vuestic-widget-body"
      v-if="hasSlotData"
      v-show="show"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vuestic-widget',
  props: {
    headerText: {
      type: String,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    startOfShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: this.startOfShow,
    };
  },
  computed: {
    hasSlotData() {
      return this.$slots.default;
    },
  },
};
</script>

<style lang="scss">
.vuestic-widget {
  background: $widget-bg;
  box-shadow: $widget-box-shadow;
  margin-bottom: $widget-mb;
  padding: 5px;

  &.no-header {
    .vuestic-widget-body {
      background: $widget-bg;
    }
  }

  &.larger-padding {
    .vuestic-widget-body {
      padding: $widget-larger-padding;
    }
  }

  &.no-h-padding {
    .vuestic-widget-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.no-v-padding {
    .vuestic-widget-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.brand-info {
    background-color: $brand-info;
    color: $white;
    box-shadow: $widget-info-shadow;
  }

  &.brand-danger {
    background-color: $brand-danger;
    color: $white;
    box-shadow: $widget-danger-shadow;
  }

  &.info-widget {
    border-top: $info-widget-border;

    &.brand-info {
      border-top-color: $brand-info;
    }

    &.brand-danger {
      border-top-color: $brand-danger;
    }
  }

  .vuestic-widget-body {
    padding: $widget-padding;
  }

  .vuestic-widget-header {
    height: $widget-header-height;
    padding: 0 $widget-padding;
    border-bottom: $widget-header-border;
    font-size: 1.375rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}
</style>
<style>
.btn.btn-micro.plus {
    padding: 0.45rem 1.8rem !important;
}
</style>
