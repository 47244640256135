import store from 'vuex-store';

export default {
  inserted(el) {
    const checkIsDesktop = () => window.matchMedia(store.getters.config.windowMatchSizeLg).matches;

    let prevMatchlg = checkIsDesktop();
    const sidebar = el.querySelector('.vuestic-sidebar');

    el.addEventListener('transitionend', () => {
      store.dispatch('isToggleWithoutAnimation', false);
    });

    window.addEventListener('resize', () => {
      if (checkIsDesktop() && !prevMatchlg) {
        sidebar.classList.remove('sidebar-hidden');
      } else if (!checkIsDesktop() && prevMatchlg) {
        store.dispatch('isToggleWithoutAnimation', true);
        sidebar.classList.add('sidebar-hidden');
      }
      prevMatchlg = checkIsDesktop();
    });
  },
};
