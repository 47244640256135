export const breadcrumbs = {
  root: {
    name: 'dashboard-user-supervisor',
    displayName: 'Home',
  },
  routes: [
    {
      name: 'dashboard-user-supervisor',
      displayName: 'Dashboard',
    },
    {
      name: 'lista-acoes-supervisor',
      displayName: 'Lista de Ações',
      children: [
        {
          name: 'cadastrar-acao-supervisor',
          displayName: 'Cadastrar Ação',
        },
      ],
    },
    {
      name: 'lista-relato-user-supervisor',
      displayName: 'Lista de Relatos',
    },
    {
      name: 'cadastrar-relato-supervisor',
      displayName: 'Cadastrar Relato',
    },
    {
      name: 'lista-chamado-supervisor',
      displayName: 'Lista de Chamados',
      children: [
        {
          name: 'cadastrar-chamado-supervisor',
          displayName: 'Cadastrar Chamado',
        },
      ],
    },
    {
      name: 'lista-ouvidoria-supervisor',
      displayName: 'Lista de Ouvidorias',
    },
    {
      name: 'cadastrar-ouvidoria-supervisor',
      displayName: 'Cadastrar Ouvidoria',
    },
    {
      name: 'lista-checklist-supervisor',
      displayName: 'Lista das Checklist',
      children: [
        {
          name: 'lista-checklist-resposta-user-supervisor',
          displayName: 'Perguntas das Checklists',
          params: ['checklist'],
          children: [
            {
              name: 'lista-checklist-resposta-pergunta-user-supervisor',
              displayName: 'Respostas das Checklists',
            },
          ],
        },
        {
          name: 'lista-checklist-duplicate-by-empresa-user-supervisor',
          displayName: 'Checklists',
          children: [
            {
              params: ['checklist'],
              name: 'lista-checklist-duplicate-user-supervisor',
              displayName: 'Duplicar',
            },
          ],
        },
        {
          name: 'cadastrar-acesso-checklist-supervisor',
          displayName: 'Definir Acesso',
        },
        {
          name: 'cadastrar-checklist-supervisor',
          displayName: 'Cadastrar Checklist',
        },
        {
          name: 'cadastrar-checklist-id-supervisor',
          displayName: 'Editar Checklist',
          params: ['checklist'],
          children: [
            {
              name: 'cadastrar-pergunta-supervisor',
              displayName: 'Cadastrar Pergunta',
            },
            {
              name: 'editar-pergunta-supervisor',
              displayName: 'Editar Pergunta',
            },
          ],
        },
      ],
    },
  ],
};
