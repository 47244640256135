// Polyfills
import 'es6-promise/auto';
import 'babel-polyfill';

// Plugins
import './plugins';

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import './i18n';
import VueSweetalert2 from 'vue-sweetalert2';

import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';

// Data Table Vue
import 'element-ui/lib/theme-chalk/index.css';

import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
import VueDataTables from 'vue-data-tables';
import router from './router';
import axios from './services/axiosBase';
import store from './store';
import App from './App.vue';

Vue.use(VueSweetalert2);

Vue.use(ElementUI);
locale.use(lang);
Vue.use(VueDataTables);
// .

Vue.component('multiselect', Multiselect);


Vue.prototype.$axios = axios;

const token = localStorage.getItem('token');

if (token) {
  Vue.prototype.$axios.defaults.headers.common.Authorization = `JWT ${token}`;
}
let t = 0;
router.beforeEach((to, from, next) => {
  store.commit('setLoading', true);
  if (store.getters.isLoggedIn) {
    if (t === 0) {
      axios.post('auth-jwt-verify/', { token: localStorage.getItem('token') }).then(() => {
        t = 1;
      }).catch(() => {
        localStorage.clear();
        next('/auth/login');
        window.location.reload();
      });
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      const empresas = JSON.parse(localStorage.getItem('empresaSelecionada')) || [];
      if (empresas.length > 1) {
        next('/auth/escolher');
      }
      next();
      return;
    }

    next('/auth/login');
  } else {
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  store.commit('setLoading', false);
});

router.afterEach((to, from) => {
  if ((to.name === 'dashboard-user' && from.name === 'dashboard-user-supervisor')
  || (to.name === 'dashboard-user' && from.name === 'dashboard-admin')
  || (to.name === 'dashboard-admin' && from.name === 'dashboard-user')) {
    window.location.reload();
  }
});
/* eslint-disable no-new */

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
