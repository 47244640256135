export const breadcrumbs = {
  root: {
    name: '/',
    displayName: 'Home',
  },
  routes: [
    {
      name: 'dashboard-admin',
      displayName: 'menu.dashboard',
    },
    {
      name: 'dashboard-user',
      displayName: 'menu.dashboard',
    },
    {
      name: 'lista-indicadores',
      displayName: 'Indicadores Cadastrados',
    },
    {
      name: 'lista-indicadores-subordinados',
      displayName: 'Subordinados',
    },
    {
      name: 'lista-relatos',
      displayName: 'Relatos',
    },
    {
      name: 'cadastrar-acao',
      displayName: 'Cadastrar Ação',
    },
    {
      name: 'cadastrar-relato',
      displayName: 'Relatar',
    },
    {
      name: 'lista-funcionarios',
      displayName: 'Lista de Funcionários',
      children: [
        {
          name: 'cadastrar-funcionario',
          displayName: 'Cadastrado de Funcionário',
        },
        {
          name: 'editar-funcionario',
          displayName: 'Editar de Funcionário',
        },
      ],
    },

    {
      name: 'editar-acao',
      displayName: 'Editar Ação',
    },
    {
      name: 'lista-ferramentas',
      displayName: 'Lista de Ferramentas',
    },
    {
      name: 'lista-empresas',
      displayName: 'Empresas Cadastradas',
      children: [
        {
          name: 'editar-empresa',
          displayName: 'Editar Empresa',
        },
        {
          name: 'cadastrar-empresa',
          displayName: 'Cadastrar Empresa',
        },
      ],
    },
    {
      name: 'lista-areas',
      displayName: 'Areas Cadastradas',
    },
    {
      name: 'forms',
      displayName: 'menu.forms',
      disabled: true,
    },
    {
      name: 'responder-checklist',
      displayName: 'Responder Checklist',
    },
    {
      name: 'lista-acoes',
      displayName: 'Lista de Ações',
    },
    {
      name: 'lista-checklist-user',
      displayName: 'Checklist Dinsponíveis',
    },
    {
      name: 'responder-checklist',
      displayName: 'Checklist',
    },
    {
      name: 'acesso',
      displayName: 'Acesso',
      disabled: true,
    },
    {
      name: 'lista-checklist',
      displayName: 'Checklist Cadastradas',
      children: [
        {
          name: 'cadastrar-checklist',
          displayName: 'Cadastrar Checklist',
        },
      ],
    },
    {
      name: 'lista-acoes',
      displayName: 'Lista de Ações',
    },
    {
      name: 'cadastrar-relato-user',
      displayName: 'Cadastrar Relato',
    },
    {
      name: 'lista-modelo-relato',
      displayName: 'Modelo de relato',
      children: [
        {
          name: 'cadastrar-relatos',
          displayName: 'Cadastar Modelo de relato',
        },
        {
          name: 'editar-relato',
          displayName: 'Editar Relato',
        },
      ],
    },
    {
      name: 'lista-chamado-user',
      displayName: 'Lista de Chamados',
      children: [
        {
          name: 'cadastrar-chamado-user',
          displayName: 'Cadastrar Chamado',
        },
      ],
    },
    {
      name: 'lista-ouvidoria-user',
      displayName: 'Lista de Ouvidorias',
    },
    {
      name: 'cadastrar-ouvidoria-user',
      displayName: 'Cadastrar Ouvidoria',
    },
  ],
};
