<template>
  <vuestic-sidebar :hidden="isOpen">
    <template slot="menu">
      <sidebar-link
        :to="{name: `dashboard-${level}`}"
        @click="closeMenu()"
      >
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-dashboard"></span>
          <span>{{ $t('menu.dashboard') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'lista-areas' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-statistics"></span>
          <span>{{ $t('Áreas') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'lista-empresas' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-building"></span>
          <span>{{ $t('Empresa') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{name:'lista-funcionarios'}">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-ui-elements"></span>
          <span>{{ $t('Funcionário') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'lista-ferramentas' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-auth"></span>
          <span>{{ $t('Ferramenta') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'lista-indicadores' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-maps"></span>
          <span>{{ $t('Indicador') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'lista-checklist' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-files"></span>
          <span>{{ $t('Checklist') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'lista-modelo-relato' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-files"></span>
          <span>Modelos de Relato</span>
        </span>
      </sidebar-link>
    </template>

  </vuestic-sidebar>

  <!--- MODELS-->

</template>

<script>

import VuesticSidebar
  from '../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar.vue';
import SidebarLink from './components/SidebarLink.vue';

export default {
  name: 'app-sidebar',
  data() {
    return {
      show: true,
    };
  },

  computed: {
    level() { return JSON.parse(localStorage.user).funcionario == null ? 'admin' : 'user'; },
  },

  components: {
    VuesticSidebar,
    SidebarLink,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    showMediumModal() {
      this.$refs.mediumModal.open();
    },
    closeMenu() {
      this.isOpen = false;
    },
  },

};

</script>
