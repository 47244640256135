/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexI18n from 'vuex-i18n'; // load vuex i18n module
import Compress from 'compress.js';

import app from './modules/app';
import checklist from './modules/checklist';
import checklistResposta from './modules/checklistResposta';
import pergunta from './modules/perguntaDaChecklist';
import areaDaChecklist from './modules/areaDaChecklist';
import acesso from './modules/acesso';
import aplicar from './modules/aplicar';
import funcionario from './modules/funcionario';
import area from './modules/area';
import empresa from './modules/empresa';

import axios from '../services/axiosBase';

import * as getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
    checklist,
    pergunta,
    areaDaChecklist,
    acesso,
    aplicar,
    funcionario,
    checklistResposta,
    area,
    empresa,
  },

  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: '/api/login/',
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            const { data } = resp;

						localStorage.setItem('token', data.token);
						localStorage.setItem('user', JSON.stringify(data.user));
						if (!data.user.is_superuser) {
							if (data.user.funcionario.empresas.length === 1) {
								localStorage.setItem(
									'empresaSelecionada',
									JSON.stringify(data.user.funcionario.empresas[0])
								);
							} else {
								localStorage.setItem(
									'empresaSelecionada',
									JSON.stringify(data.user.funcionario.empresas)
								);
							}
						}

						axios.defaults.headers.common.Authorization = data.token;
						commit('auth_success', data);

						resolve(resp);
					})
					.catch(err => {
						commit('auth_error');
						localStorage.removeItem('token');
						localStorage.removeItem('user');
						localStorage.removeItem('empresaSelecionada');
						reject(err);
					});
			});
		},
		logout({ commit }) {
			return new Promise(resolve => {
				commit('logout');
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('empresaSelecionada');
				delete axios.defaults.headers.common.Authorization;
				resolve();
			});
		},
		async srcToFile(context, payload) {
			const { src, fileName, mimeType } = payload;
			const res = await fetch(src);
			const buf = await res.arrayBuffer();
			return new File([buf], fileName, { type: mimeType });
		},
		async selectImage({ dispatch }, img) {
			const compress = new Compress();
			const data = await compress.compress(img, {
				size: 1,
				quality: 0.75,
				maxWidth: 1920,
				maxHeight: 1920,
				resize: true
			});
			const props = {
				src: data[0].prefix + data[0].data,
				fileName: data[0].alt,
				mimeType: data[0].ext
			};
			const file = await dispatch('srcToFile', props);
			return file;
		},
		transformObjectInFormData(constext, payload) {
			const fb = new FormData();
			Object.keys(payload).forEach(key => {
				if (payload[key]) {
					if (Array.isArray(payload[key]) && payload[key].length > 0) {
						payload[key].forEach(val => {
							fb.append(`${key}`, val);
						});
					} else if (!Array.isArray(payload[key])) {
						fb.append(key, payload[key]);
					}
				}
			});
			return fb;
		},
		dataURLtoBlob(context, dataurl) {
			const arr = dataurl.split(',');
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			// eslint-disable-next-line no-plusplus
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		}
	},
	state: {
		status: '',
		token: localStorage.getItem('token') || '',
		user: {},
		tiposDeRespostaDeTexto: [
			{ id: 1, nome: 'Texto sem formatação' },
			{ id: 2, nome: 'Campo Monétario' },
			{ id: 3, nome: 'Campo Quantidade' },
			{ id: 4, nome: 'Campo de Número Real' },
			{ id: 5, nome: 'Campo Data' },
			{ id: 6, nome: 'Campo CNPJ' },
			{ id: 7, nome: 'Campo CPF' },
			{ id: 8, nome: 'Texto Longo' },
			{ id: 9, nome: 'Placa de Automóvel' }
		]
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading';
		},
		auth_success(state, data) {
			state.status = 'success';
			state.token = data.token;
			state.user = data.user;
		},
		auth_error(state) {
			state.status = 'error';
		},
		logout(state) {
			state.status = '';
			state.token = '';
		}
	}
});

Vue.use(VuexI18n.plugin, store);

export default store;
