<template>
  <div id="app-layout-relato">
    <nav class="vuestic-navbar navbar navbar-toggleable-md">
      <div class="vuestic-navbar__logo">
        <img
          src="@/assets/img/gestorGO.png"
          width="120px"
        >
      </div>
    </nav>
    <br>
    <router-view />

  </div>
</template>

<script>

export default {
  name: 'app-layout-relato',
};
</script>
