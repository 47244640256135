import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getAreas() {
    try {
      const res = await axios.get('api/area_da_checklist/');
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getAreaById(store, id) {
    try {
      const res = await axios.get(`api/area_da_checklist/${id}/`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async postArea(store, area) {
    try {
      const res = await axios.post('/api/area_da_checklist/', area);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async putArea(store, area) {
    try {
      const { id } = area;
      const res = await axios.put(`/api/area_da_checklist/${id}/`, area);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteArea(store, id) {
    try {
      const res = await axios.delete(`/api/area_da_checklist/${id}/`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
