<template>
  <div class="va-row">

  <span class="flex-center header-selector i-menu-expanded"
        @click="$emit('update:isOpen', !isOpen)"
  />
  </div>
</template>

<script>
export default {
  name: 'header-selector',
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.header-selector {
  cursor: pointer;
}

</style>
