import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getChecklistRespostaBySituation(
    store,
    { checklist, situacao, minhas } = {},
  ) {
    try {
      const empresa = await localStorage.empresaSelecionada;
      const params = {
        empresa,
        checklist,
        situacao,
      };
      if (minhas) {
        params.minhas = '';
      }
      const res = await axios.get('api2/checklist-resposta', { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
