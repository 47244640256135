<template>
  <div class="vuestic-layout"
       v-resize
       :class="classObject"
  >
    <slot></slot>
    <div class="content-wrap" id="content-wrap">
      <slot name="content"></slot>
    </div>
    <div class="made-by-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Resize from '../../../directives/ResizeHandler';

export default {
  name: 'vuestic-layout',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    resize: Resize,
  },
  computed: {
    classObject() {
      return {
        'layout-fixed': this.fixed,
      };
    },
  },
};
</script>

<style lang="scss">
$break-clr: 458px;
.vuestic-layout {
  .content-wrap {
    margin-left: 250px;
    transition: margin-left 0.3s ease;
    padding: 0px;
    .pre-loader {
      position: absolute;
      left: $vuestic-preloader-left;
      top: $vuestic-preloader-top;
    }
    @include media-breakpoint-down(md) {
      padding: $content-mobile-wrap;
      margin-left: 0;
      .sidebar-hidden & {
        margin-left: 0;
        padding-top: 0px;
      }
    }
  }
  &-fixed {
    .content-wrap {
      padding-right: $layout-padding-right;
      padding-top: 0px;
      @include media-breakpoint-down(md) {

        margin-left: 0;
      }
    }
  }

  .made-by-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:0px;
    padding-bottom: $made-by-footer-pb;
    // position: absolute;
    bottom: 0;
    height: calc(#{$layout-padding} + #{$widget-mb});
    width: 100%;
  }
}
@media screen and (max-width: $break-clr){
  #content-wrap{
    padding: 0%;
  }
  .va-layout.gutter--lg {
      padding: 0.5rem!important;
  }
}
</style>
