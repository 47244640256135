<template>
  <vuestic-layout v-layout>
    <app-navbar
      :isOpen="opened"
      @toggle-menu="toggleSidebarUser"
    />
    <app-sidebar-user-supervisor
      :isOpen="opened"
      @toggle-menu="toggleSidebarUser"
    />

    <main
      slot="content"
      id="content"
      class="content va-layout gutter--lg fluid"
      role="main"
    >
      <app-breadcrumbs />
      <vuestic-pre-loader
        v-show="isLoading"
        class="pre-loader"
      />
      <router-view />
    </main>

  </vuestic-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import VuesticLayout
  from '../../../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout.vue';
import AppNavbar from '../../admin/app-navbar/AppNavbar.vue';
import AppSidebarUserSupervisor from '../../admin/app-sidebar/AppSidebarUserSupervisor.vue';
import AppBreadcrumbs from './app-breadcrumbs/AppBreadcrumbs.vue';
import Layout from '../../../vuestic-theme/vuestic-directives/Layout';

export default {
  name: 'app-layout-user',
  components: {
    VuesticLayout,
    AppNavbar,
    AppSidebarUserSupervisor,
    AppBreadcrumbs,
  },
  directives: {
    layout: Layout,
  },
  data() {
    return {
      opened: true,
    };
  },
  methods: {
    toggleSidebarUser(opened) {
      this.opened = opened;
    },
  },
  computed: {
    ...mapGetters([
      'isLoading',
    ]),
  },
};
</script>
