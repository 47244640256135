import Vue from 'vue';
import Platform from './plugins/platform';

export const installQuasarPlatform = () => {
  const queues = {
    server: [], // on SSR update
    takeover: [], // on client takeover
  };

  const framework = {};
  Platform.install(framework, queues, Vue);
};
