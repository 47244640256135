<template>
  <div class="va-row">
    <div class="flex md12 xs12">
        <vuestic-breadcrumbs
          :breadcrumbs="breadcrumbs"
          :currentRouteName="currentRouteName"
        />
    </div>
  </div>
</template>

<script>
import { breadcrumbs } from './Breadcrumbs';

export default {
  name: 'app-breadcrumbs',
  data() {
    return {
      breadcrumbs,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};

</script>

<style>

</style>
