<template>
  <div class="profile-dropdown flex-center">
    <span class="profile-dropdown__avatar-container">
      <img
        src="@/assets/img/user.svg"
        alt=""
      >
    </span>
    <vuestic-dropdown position="bottom">
      <div
        v-if="isChangeEmpresa"
        class="dropdown-item plain-link-item"
      >
        <div
          class="plain-link cor"
          style="cursor: pointer;"
          @click="$router.push({name:'escolher-empresa'})"
        >
          Alterar Empresa
        </div>
      </div>
      <div class="dropdown-item plain-link-item">
        <div
          class="plain-link cor"
          style="cursor: pointer;"
          @click="$router.push({name:'editar-senha'})"
        >Alterar senha</div>
      </div>

      <div
        v-if="user.funcionario != null"
        class="dropdown-item plain-link-item"
      >
        <div
          class="plain-link cor"
          style="cursor: pointer;"
          @click="$router.push({name: nameUserOuvidor})"
        >
          Ouvidoria
        </div>
      </div>

      <div
        class="dropdown-item plain-link-item"
        @click.prevent="logout"
      >
        <a
          class="plain-link"
          href="#"
        >Sair</a>
      </div>
    </vuestic-dropdown>
  </div>
</template>

<script>
export default {
  name: 'profile-section',
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'Alterar Senha',
          redirectTo: '',
        },
        {
          name: 'Sair',
          redirectTo: 'login',
        },
        {
          name: 'Alterar Empresa',
          redirectTo: 'escolher',
        },
      ],
    },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'));
    },
    nameUserOuvidor() {
      const { user } = this;
      return user.groups.indexOf(1) !== -1 ? 'cadastrar-ouvidoria-supervisor' : 'cadastrar-ouvidoria-user';
    },
    isChangeEmpresa() {
      const { user } = this;
      return user.funcionario != null && user.funcionario.empresas.length > 0;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/auth/login');
        });
    },
  },
};
</script>
<style lang="scss">
@import '../../../../../vuestic-theme/vuestic-sass/resources/resources';
.cor {
	color: #4ae387;
}

.profile-dropdown {
	cursor: pointer;

	&__avatar-container {
		display: inline-block;
		width: 50px;
		height: 50px;
		background-color: white;
		border-radius: 50%;
		border: 2px solid $lighter-gray;
		overflow: hidden;

		img {
			height: 100%;
			width: 100%;
		}
	}
	.plain-link-item {
		cursor: pointer;
	}
}
</style>
