import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getAcessos(store, { params = {} } = {}) {
    try {
      const res = await axios.get('api/acesso/', { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async postAcesso(store, acesso) {
    try {
      const res = await axios.post('api/acesso/', acesso);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async putAcesso(store, acesso) {
    const { id } = acesso;
    try {
      const res = await axios.put(`api/acesso/${id}/`, acesso);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
