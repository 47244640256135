import Vue from 'vue';
import Router from 'vue-router';
import AppLayout from '../components/admin/AppLayout.vue';
import AppLayoutUser from '../components/admin/AppLayoutUser.vue';
import AppLayoutUserSupervisor from '../components/user-supervisor/components/AppLayoutUserSupervisor.vue';
import AppLayoutRelato from '../components/admin/AppLayoutRelato.vue';
import AuthLayout from '../components/auth/AuthLayout.vue';
import lazyLoading from './lazyLoading';

Vue.use(Router);

const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

let level = 'admin';

if (localStorage.user) {
  level = JSON.parse(localStorage.user).is_superuser ? 'admin' : 'user';
}

function authOuvidoria(next) {
  const user = JSON.parse(localStorage.user);
  if (user.groups.indexOf(2) === -1) {
    next('/');
  }
  next();
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: {
        name: `dashboard-${level}`,
      },
    },

    {
      name: 'editar-senha',
      path: '/senha',
      component: lazyLoading('forms/forms-admin/editar/senha'),
    },

    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          component: lazyLoading('auth/login/Login'),
        },

        {
          name: 'esqueci-minha-senha-email',
          path: 'senha',
          component: lazyLoading('auth/login/EsqueciSenhaEmail'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
        {
          name: 'escolher-empresa',
          path: 'escolher',
          component: lazyLoading('forms/forms-admin/editar/escolherempresa'),
        },
      ],
    },
    {
      path: '/rest-auth',
      component: AuthLayout,
      children: [
        {
          name: 'esqueci-minha-senha-confirmacao',
          path: 'password/reset/confirm/:uid/:token/',
          component: lazyLoading('auth/login/EsquiSenhaConfirmar'),
        },
      ],
    },
    {
      path: '/informacoes',
      component: EmptyParentComponent,
      children: [
        {
          name: 'informacao',
          path: 'informacoes',
          component: lazyLoading('informacoes/informacao'),
        },
      ],
    },
    {
      path: '/relato/:empresa',
      name: 'relato-direto',
      component: lazyLoading('relatos/FazerRelato'),
    },
    {
      path: '/relatos',
      component: AppLayoutRelato,
      children: [
        {
          name: 'relatos',
          path: 'relato',
          component: lazyLoading('relatos/Relatos'),
        },
        {
          name: 'relatar',
          path: 'relato/:nome/:empresa',
          component: lazyLoading('relatos/FazerRelato'),
        },
      ],
    },
    {
      name: 'ouvidoria-anonima',
      path: '/ouvidoria/:empresa',
      component: lazyLoading('ouvidoria/fazerOuvidoria'),
    },
    {
      name: 'UserSupervisor', // Ou funcionário
      path: '/user-supervisor',
      component: AppLayoutUserSupervisor,
      meta: {
        requiresAuth: true,
      },
      beforeEnter(to, from, next) {
        const user = JSON.parse(localStorage.user);
        if (user.is_superuser) {
          next('/admin/dashboard');
        } else if (user.groups.indexOf(1) === -1) {
          next('/user/dashboard');
        }
        next();
      },

      children: [
        {
          name: 'dashboard-user-supervisor',
          path: 'dashboard',
          component: lazyLoading('user/dashboard/dashboard-user'),
        },

        {
          name: 'formulario-user-supervisor',
          path: 'formulario',
          component: EmptyParentComponent,
          children: [
            {
              name: 'cadastrar-chamado-supervisor',
              path: 'chamado',
              component: lazyLoading('user/form/chamado'),
            },
            {
              name: 'cadastrar-ouvidoria-supervisor',
              path: 'ouvidoria',
              component: lazyLoading('user/form/ouvidoria'),
            },
            {
              name: 'cadastrar-acao-supervisor',
              path: 'acao',
              component: lazyLoading('user-supervisor/form/acao'),
            },
            {
              name: 'cadastrar-relato-supervisor',
              path: 'relato',
              component: lazyLoading('user/form/relato'),
            },
            {
              name: 'editar-acao-supervisor',
              path: 'acao/:id',
              component: lazyLoading('user/form/editar/acao'),
            },

            {
              name: 'cadastrar-checklist-supervisor',
              path: 'checklist',
              component: lazyLoading('forms/forms-admin/cadastrarChecklist'),
              props: { routerSuffix: '-supervisor' },
            },
            {
              name: 'cadastrar-checklist-id-supervisor',
              path: 'checklist/:checklist',
              component: lazyLoading('forms/forms-admin/cadastrarChecklist'),
              props: { routerSuffix: '-supervisor' },
            },
            {
              name: 'cadastrar-acesso-checklist-supervisor',
              path: 'checklist/:checklist/acesso',
              component: lazyLoading('checklist/checklistAcesso'),
              props: { routerSuffix: '-supervisor' },
            },
            {
              name: 'cadastrar-pergunta-supervisor',
              path: 'pergunta/new/:checklist/:area',
              component: lazyLoading('forms/forms-admin/cadastrarPergunta'),
              props: { routerSuffix: '-supervisor' },
            },
            {
              name: 'editar-pergunta-supervisor',
              path: 'pergunta/:checklist/:id',
              component: lazyLoading('forms/forms-admin/cadastrarPergunta'),
              props: { routerSuffix: '-supervisor' },
            },
          ],
        },
        {
          name: 'listagem-user-supervisor',
          path: 'listagem',
          component: EmptyParentComponent,
          children: [
            {
              name: 'lista-chamado-supervisor',
              path: 'chamado',
              component: lazyLoading('user/lista/chamado'),
            },
            {
              name: 'lista-ouvidoria-supervisor',
              path: 'ouvidoria',
              component: lazyLoading('user/lista/ouvidoria'),
              beforeEnter(to, from, next) {
                authOuvidoria(next);
              },
            },
            {
              name: 'lista-acoes-supervisor',
              path: 'acoes',
              component: lazyLoading('user/lista/listaAcoes'),
            },
            /*             {
              name: 'responder-checklist-supervisor',
              path: 'responder/:id/:acesso',
              component: lazyLoading('user/lista/responderChecklist'),
            }, */
            {
              name: 'lista-relato-user-supervisor',
              path: 'relato',
              component: lazyLoading('user-supervisor/lista/relato'),
            },
            {
              path: 'checklist',
              component: EmptyParentComponent,
              children: [
                {
                  name: 'lista-checklist-supervisor',
                  path: '',
                  component: lazyLoading('user-supervisor/lista/checklist'),
                },
                {
                  path: 'duplicate',
                  component: EmptyParentComponent,
                  children: [
                    {
                      name: 'lista-checklist-duplicate-by-empresa-user-supervisor',
                      path: '',
                      component: lazyLoading(
                        'checklist/checklistByEmpresaDuplicate',
                      ),
                    },
                    {
                      name: 'lista-checklist-duplicate-user-supervisor',
                      path: ':checklist',
                      component: lazyLoading(
                        'checklist/checklistDuplicate',
                      ),
                    },
                  ],
                },
                {
                  path: ':checklist',
                  component: EmptyParentComponent,
                  children: [
                    {
                      name: 'lista-checklist-resposta-pergunta-user-supervisor',
                      path: 'respostas/:resposta',
                      component: lazyLoading(
                        'checklist/checklistRespostaDasPerguntas',
                      ),
                    },
                    {
                      name: 'lista-checklist-resposta-user-supervisor',
                      path: 'respostas',
                      component: lazyLoading('checklist/checklistRespostas'),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'User', // Ou funcionário
      path: '/user',
      component: AppLayoutUser,
      meta: {
        requiresAuth: true,
      },
      beforeEnter(to, from, next) {
        const user = JSON.parse(localStorage.user);
        if (user.is_superuser) {
          next('/admin/dashboard');
        } else if (user.groups.indexOf(1) !== -1) {
          next('/user-supervisor/dashboard');
        }
        next();
      },

      children: [
        {
          name: 'dashboard-user',
          path: 'dashboard',
          component: lazyLoading('user/dashboard/dashboard-user'),
        },

        {
          name: 'formulario-user',
          path: 'formulario',
          component: EmptyParentComponent,
          children: [
            {
              name: 'cadastrar-chamado-user',
              path: 'chamado',
              component: lazyLoading('user/form/chamado'),
            },
            {
              name: 'cadastrar-ouvidoria-user',
              path: 'ouvidoria',
              component: lazyLoading('user/form/ouvidoria'),
            },
            {
              name: 'cadastrar-acao',
              path: 'acao',
              component: lazyLoading('user/form/acao'),
            },
            {
              name: 'editar-acao',
              path: 'acao/:id',
              component: lazyLoading('user/form/editar/acao'),
            },
            {
              name: 'cadastrar-relato-user',
              path: 'relato',
              component: lazyLoading('user/form/relato'),
            },
          ],
        },
        {
          name: 'listagem-user',
          path: 'listagem',
          component: EmptyParentComponent,
          children: [
            {
              name: 'lista-chamado-user',
              path: 'chamado',
              component: lazyLoading('user/lista/chamado'),
            },
            {
              name: 'lista-ouvidoria-user',
              path: 'ouvidoria',
              component: lazyLoading('user/lista/ouvidoria'),
              beforeEnter(to, from, next) {
                authOuvidoria(next);
              },
            },
            {
              name: 'lista-acoes',
              path: 'acoes',
              component: lazyLoading('user/lista/listaAcoes'),
            },
            {
              name: 'lista-checklist-user',
              path: 'checklist',
              component: lazyLoading('user/lista/listaChecklistUser'),
            },
            {
              name: 'responder-checklist',
              path: 'responder/:id/:acesso',
              component: lazyLoading('user/lista/responderChecklist'),
            },
          ],
        },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      meta: {
        requiresAuth: true,
      },
      beforeEnter(to, from, next) {
        const user = JSON.parse(localStorage.user);
        if (!user.is_superuser) {
          next('/user/dashboard');
        }

        next();
      },
      children: [
        {
          name: 'dashboard-admin',
          path: 'dashboard',
          component: lazyLoading('dashboard/Dashboard'),
        },
        {
          name: 'formulario-admin',
          path: 'formulario',
          component: EmptyParentComponent,
          children: [
            {
              name: 'cadastrar-empresa',
              path: 'empresa',
              component: lazyLoading('forms/forms-admin/cadastrarEmpresa'),
            },
            {
              name: 'cadastrar-checklist',
              path: 'checklist',
              component: lazyLoading('forms/forms-admin/cadastrarChecklist'),
            },
            {
              name: 'cadastrar-checklist-id',
              path: 'checklist/:checklist',
              component: lazyLoading('forms/forms-admin/cadastrarChecklist'),
            },
            {
              name: 'cadastrar-pergunta',
              path: 'pergunta/new/:checklist/:area',
              component: lazyLoading('forms/forms-admin/cadastrarPergunta'),
            },
            {
              name: 'editar-pergunta',
              path: 'pergunta/:checklist/:area/:pergunta',
              component: lazyLoading('forms/forms-admin/cadastrarPergunta'),
            },
            {
              name: 'acesso',
              path: 'acesso/:checklist',
              component: lazyLoading('forms/forms-admin/acesso'),
            },
            {
              name: 'cadastrar-funcionario',
              path: 'funcionario',
              component: lazyLoading('forms/forms-admin/cadastrarFuncionario'),
            },
            {
              name: 'cadastrar-grupo',
              path: 'grupo',
              component: lazyLoading(
                'forms/forms-admin/cadastrarGruposDeRelatos',
              ),
            },
            {
              name: 'cadastrar-relatos',
              path: 'relatos',
              component: lazyLoading('forms/forms-admin/relatos'),
            },
            {
              name: 'editar-relato',
              path: 'relato/:id',
              component: lazyLoading('forms/forms-admin/editar/relatos'),
            },
          ],
        },
        {
          name: 'listagem-admin',
          path: 'listagem',
          component: EmptyParentComponent,
          children: [
            {
              name: 'lista-empresas',
              path: 'empresas',
              component: lazyLoading('listas/listaEmpresas'),
            },
            {
              name: 'lista-relatos',
              path: 'relato',
              component: lazyLoading('user/lista/ListaRelatos'),
            },
            {
              name: 'lista-funcionarios',
              path: 'funcionarios',
              component: lazyLoading('listas/listaFuncionarios'),
            },
            {
              name: 'lista-ferramentas',
              path: 'ferramentas',
              component: lazyLoading('listas/listaFerramentas'),
            },
            {
              name: 'lista-indicadores',
              path: 'indicadores',
              component: lazyLoading('listas/listaIndicadores'),
            },
            {
              name: 'lista-indicadores-subordinados',
              path: 'subordinados',
              component: lazyLoading('listas/listaIndicadoresSubordinados'),
              props: route => ({
                ...route.params,
              }),
            },
            {
              name: 'lista-areas',
              path: 'areas',
              component: lazyLoading('listas/listaAreas'),
            },
            {
              name: 'lista-checklist',
              path: 'checklist',
              component: lazyLoading('listas/listaChecklist'),
            },
            {
              name: 'lista-modelo-relato',
              path: 'relatos',
              component: lazyLoading('listas/relatos'),
            },
          ],
        },

        {
          name: 'editar',
          path: 'editar',
          component: EmptyParentComponent,
          children: [
            {
              name: 'editar-empresa',
              path: 'empresa/:id',
              component: lazyLoading('forms/forms-admin/editar/empresa'),
            },
            {
              name: 'editar-funcionario',
              path: 'funcionario/:id',
              component: lazyLoading('forms/forms-admin/editar/funcionario'),
            },
          ],
        },
      ],
    },
  ],
});
