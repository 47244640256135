import moment from 'moment';
import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getChecklistPorAcesso(store, { id, params = {} } = {}) {
    try {
      const res = await axios.get(`api2/checklist-acesso/${id}/`, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getChecklistFullById(store, { id, params = {} } = {}) {
    try {
      const res = await axios.get(`api2/checklist-full/${id}/`, { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getChecklistFull(store, { params = {} } = {}) {
    try {
      const res = await axios.get('api2/checklist-full/', { params });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async duplicateChecklist(store, checklist) {
    try {
      const res = await axios.post('api2/modelo/', checklist);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async postChecklist(store, checklist) {
    try {
      const res = await axios.post('api/checklist/', checklist);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async putChecklist(store, checklist) {
    const { id } = checklist;
    try {
      const res = await axios.put(`api/checklist/${id}/`, checklist);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteChecklist(store, id) {
    try {
      await axios.delete(`/api/checklist/${id}.json`);
    } catch (error) {
      throw error;
    }
  },

  checklistSerializer(store, checklists = []) {
    const typesResponse = {
      1: 'Sim',
      2: 'Não',
      3: 'Razoável',
      4: 'Não se Aplica',
    };
    const serializedChecklists = [];
    checklists.forEach((checklist) => {
      const checklistName = checklist.nome;
      checklist.area_da_checklist.forEach((area) => {
        const areaName = area.nome;
        area.pergunta.forEach((pergunta) => {
          const perguntaName = pergunta.pergunta;
          pergunta.pergunta_resposta.forEach((resposta) => {
            let respostaName;
            if (resposta.tipo1) {
              respostaName = typesResponse[resposta.tipo1];
            }
            if (resposta.tipo2) {
              respostaName = typesResponse[resposta.tipo2];
            }
            if (resposta.tipo3) {
              respostaName = typesResponse[resposta.tipo3];
            }
            if (resposta.tipo4) respostaName = resposta.tipo4;

            const respostaCreate = moment(resposta.data_cadastro).format(
              'DD/MM/YYYY',
            );
            const respostaFinish = moment(resposta.data_finalização).format(
              'DD/MM/YYYY',
            );
            const respostaDateEnd = moment(resposta.respondido_em).format(
              'DD/MM/YYYY',
            );
            const respostaResponsible = resposta.responsavel_resposta_nome;
            const { comentario } = resposta;

            const imagem =							resposta.imagem !== null
              ? axios.defaults.baseURL + resposta.imagem
              : null;

            serializedChecklists.push({
              checklistName,
              areaName,
              perguntaName,
              respostaName,
              respostaCreate,
              respostaFinish,
              respostaDateEnd,
              respostaResponsible,
              comentario,
              imagem,
            });
          });
        });
      });
    });
    return serializedChecklists;
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
