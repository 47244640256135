import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import YmapPlugin from 'vue-yandex-maps';

import _ from 'lodash';
import VuesticPlugin from '@/vuestic-theme/vuestic-plugin';

Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.use(VuesticPlugin);
Vue.use(YmapPlugin);
Vue.use(VueTheMask);
// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
