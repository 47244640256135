import axios from '../../services/axiosBase';

const state = {};

// getters
const getters = {};

// actions
const actions = {
  async getAplicar() {
    try {
      const res = await axios.get('api2/aplicar/');
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async postAplicar(store, aplicar) {
    try {
      const res = await axios.post('api2/aplicar/', aplicar);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async putAplicar(store, aplicar) {
    const { id } = aplicar;
    try {
      const res = await axios.put(`api2/aplicar${id}/`, aplicar);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
