<template>
  <vuestic-navbar>
    <header-selector slot="selector"
:isOpen.sync="valueProxy"
class="background"/>
    <profile-dropdown class="col nav-item">
      <img src=""/>
    </profile-dropdown>
  </vuestic-navbar>

</template>

<script>
import { mapGetters } from 'vuex';
import VuesticNavbar
  from '../../../vuestic-theme/vuestic-components/vuestic-navbar/VuesticNavbar.vue';
import HeaderSelector from './components/HeaderSelector.vue';
import ProfileDropdown from './components/dropdowns/ProfileDropdown.vue';

export default {
  name: 'app-navbar',

  components: {
    VuesticNavbar,
    HeaderSelector,
    ProfileDropdown,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valueProxy: {
      get() {
        return this.isOpen;
      },
      set(opened) {
        this.$emit('toggle-menu', opened);
      },
      ...mapGetters(['isLoading']),
    },
  },
};
</script>

<style>

.vuestic-pre-loader{
  background: none;
}
</style>
