<template>
  <vuestic-sidebar :hidden="isOpen">
    <template slot="menu">
      <sidebar-link :to="{ name: 'dashboard-user-supervisor' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-dashboard"></span>
          <span>{{ $t('Dashboard') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'lista-acoes-supervisor' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-auth"></span>
          <span>{{ $t('Ações') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'lista-relato-user-supervisor' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon va-icon ion ion-ios-warning-outline"></span>
          <span>{{ $t('Relatos') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'lista-chamado-supervisor' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon ion va-icon fa fa-bullhorn"></span>
          <span>{{ $t('Chamado') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'lista-checklist-supervisor' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon ion va-icon fa fa-check"></span>
          <span>{{ $t('Checklist') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link
        v-if="isOuvidor"
        :to="{ name: 'lista-ouvidoria-supervisor' }"
      >
        <span slot="title">
          <span class="sidebar-menu-item-icon ion va-icon fa fa-universal-access"></span>
          <span>{{ $t('Ouvidoria') }}</span>
        </span>
      </sidebar-link>
    </template>

  </vuestic-sidebar>

  <!--- MODELS-->

</template>

<script>

import VuesticSidebar
  from '../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar.vue';
import SidebarLink from './components/SidebarLink.vue';

export default {
  name: 'app-sidebar-user',

  data() {
    return {
      empresaSelecionada: localStorage.empresaSelecionada,
    };
  },

  components: {
    VuesticSidebar,
    SidebarLink,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    showMediumModal() {
      this.$refs.mediumModal.open();
    },
  },
  computed: {
    isOuvidor() {
      return JSON.parse(localStorage.user).groups.findIndex(a => a === 2) !== -1;
    },
    /* urlOuvidor() {
      const ouvidor = JSON.parse(localStorage.user).groups.findIndex(a => a === 2) === -1;
      return ouvidor ? 'cadastrar-ouvidoria-supervisor' : 'lista-ouvidoria-supervisor';
    }, */
  },

};

</script>
